import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Image,
  Text,
  Button
} from '@chakra-ui/react';
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react';
import Footer from './Footer'

import GEM_ABI from './abi/GemAbi.json'
import REDRUBY_ABI from './abi/RedRubyAbi.json'

const { ethers, Wallet } = require("ethers");

const GEM_CONTRACT = '0x8d58A861cb7016554a362053B084887b509B8eDd';
const REDRUBY_CONTRACT = '0x46D4524fbcb3C7aeA67Ff85Ed4ca8996F1acEA6B';

const Hero = () => {
  const { address: connectedAddress, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [provider, setProvider] = useState(null);
  const [gemBalance, setGemBalance] = useState('0');
  const [mintedCount, setMintedCount] = useState('0');
  const [nftBalance, setNftBalance] = useState('0');

  const initializeProvider = () => new ethers.providers.Web3Provider(walletProvider);

  const fetchGemBalance = async () => {
    try {
      if (connectedAddress && walletProvider) {
        const provider = initializeProvider();
        const gemContract = new ethers.Contract(GEM_CONTRACT, GEM_ABI, provider);
        const signer = provider.getSigner();
        const balance = await gemContract.connect(signer).balanceOf(connectedAddress);
        const formattedBalance = ethers.utils.formatEther(balance);
        setGemBalance(formattedBalance.toString());
      } else {
        setGemBalance('0.00');
      }
    } catch (error) {
      console.error('Error fetching Gem balance:', error);
      setGemBalance('0.00');
    }
  };

  const fetchNftBalance = async () => {
    try {
      if (connectedAddress && walletProvider) {
        const provider = initializeProvider();
        const redrubyContract = new ethers.Contract(REDRUBY_CONTRACT, REDRUBY_ABI, provider);
        const nftBalance = await redrubyContract.balanceOf(connectedAddress);
        setNftBalance(nftBalance.toString());
      } else {
        setNftBalance('0');
      }
    } catch (error) {
      console.error('Error fetching NFTs minted:', error);
      setNftBalance('0');
    }
  }; 

  const fetchMintCount = async () => {
    try {
      if (connectedAddress && walletProvider) {
        const provider = initializeProvider();
        const redrubyContract = new ethers.Contract(REDRUBY_CONTRACT, REDRUBY_ABI, provider);
        const mintCount = await redrubyContract.mintedCount();
        setMintedCount(mintCount.toString());
      } else {
        setMintedCount('0');
      }
    } catch (error) {
      console.error('Error fetching NFTs minted:', error);
      setMintedCount('0');
    }
  };  

  useEffect(() => {
    fetchGemBalance();
    fetchMintCount();
    fetchNftBalance();
  }, [connectedAddress, walletProvider]);

  function btnClick() {
    window.location.href = "https://www.redstone.market/collection/0x46d4524fbcb3c7aea67ff85ed4ca8996f1acea6b";
  };

  return (
    <>
    <Box p={4} mb='4'>
      <Text
        mt={{ base: '0', md: '-6', lg: '-10' }}
        textAlign="center"
        color="#C00038"
        fontSize={{ base: '6rem', md: '8rem', lg: '10rem' }}
      >
        RedRuby
      </Text>
      <Text
        mt={{ base: '-6', md: '-10', lg: '-10' }}
        textAlign="center"
        color="black"
        fontSize={{ base: '1.6rem', md: '2rem', lg: '2rem' }}
      >
        You have {gemBalance} GEM<br></br>and {nftBalance} RedRuby NFT
      </Text>
      <Box mt='4' display='flex' justifyContent='center'>
        <Image h={{base: 'auto', md: '200px', lg: '300px'}} borderRadius='10' src='./rubyonwood.jpeg' />
      </Box>
      <Box mt='4' display='flex' justifyContent='center'>
        <Button onClick={btnClick} borderRadius='full' size='lg' bg='#C00038' color='white' _hover={{bg: '#C00038', color: 'white'}}>Mint RedRuby NFT</Button>
      </Box>
      <Box mt='4'>
        <Text fontSize='1.6rem' textAlign='center'>RedRuby NFT</Text>
        <Text fontSize='1.2rem' textAlign='center'>Supply: {mintedCount} / 5000</Text>
        <Text fontSize='1.2rem' textAlign='center'>Max Mint: 5</Text>
        <Text fontSize='1.2rem' textAlign='center'>Price: 0.000069 ETH</Text>
      </Box>
    </Box>
    <Footer />
    </>
  );
};

export default Hero;
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Navigation from './components/Navigation';
import Hero from './components/Hero';

const App = () => {

  return (
    <Router>
        <div style={{ height: '100%', overflowY: 'auto' }}>
          <Navigation />
          <Routes>
            <Route path="/" element={<Hero />} />
          </Routes>
        </div>
    </Router>
  );
};

export default App;

// src/components/Web3Connect.js
import React from 'react';
import { Flex } from '@chakra-ui/react';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import { web3Modal } from '../web3ModalConfig';

const Web3Connect = ({ onConnect }) => {

    return (
        <Flex>
            <w3m-button size='md' />
        </Flex>
    );
};

export default Web3Connect;


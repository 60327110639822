// src/web3ModalConfig.js
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import './index.css'

const projectId = 'e874c2d0835062b4ace978bf0ecda5d9';

const redstone = {
  chainId: 690,
  name: 'Redstone',
  currency: 'ETH',
  explorerUrl: 'https://explorer.redstone.xyz',
  rpcUrl: 'https://rpc.redstonechain.com',
};

const metadata = {
  name: 'RedRuby',
  description: 'Just a community experiment on RedStone.',
  url: 'https://redruby.world',
  icons: ['https://ivory-deep-piranha-393.mypinata.cloud/ipfs/Qmce6svRn8svmdQMxCeqJcLWmJtrvpCZnNnxprzXT3qduy'],
};

export const web3Modal = createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [redstone],
  defaultChain: 690,
  projectId,
  chainImages: {
    690: './redstone.svg',
  },
  themeMode: 'light',
  themeVariables: {
    '--w3m-accent': '#C00038',
    '--w3m-color-mix': '#C00038',
    '--w3m-color-mix-strength	': '20',
    '--w3m-font-family': "'Jersey 25', sans-serif",
  },
});

// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { ChakraProvider, CSSReset, extendTheme } from '@chakra-ui/react';
import './index.css'

const root = createRoot(document.getElementById('root'));

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: '#ffffff',
        color: 'black',
        fontFamily: "'Jersey 25', sans-serif",
      },
    },
  },
});

root.render(<React.StrictMode>
  <ChakraProvider theme={theme}>
    <CSSReset />
    <App />
  </ChakraProvider>
  </React.StrictMode>);

import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Flex,
  Spacer,
  Button,
  useDisclosure,
  VStack,
  Link,
  Text,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Spinner,
  useToast
} from '@chakra-ui/react';

import ConnectButton from './Web3Connect';
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react';

import CLAIM_ABI from './abi/ClaimAbi.json';

const { ethers, Wallet } = require("ethers");

const CLAIM_CONTRACT = '0xca6b551FE1532Aa7198ECa87570986d2c96f2433';

const Navigation = () => {
  const { address: connectedAddress, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [provider, setProvider] = useState(null);
  const [gemToClaim, setGemToClaim] = useState('0');
  const [nftId, setNftId] = useState('');
  const [loading, setLoading] = useState(false);
  const [addingToWallet, setAddingToWallet] = useState(false);
  const toast = useToast(); // Toast hook

  const claimGem = async () => {
    try {
      if (!nftId) {
        toast({
          title: "Please enter an NFT ID",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      setLoading(true); // Set loading state to true

      // Initialize provider
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();

      // Initialize contract instance
      const gemContract = new ethers.Contract(CLAIM_CONTRACT, CLAIM_ABI, signer);

      // Call the contract function to claim
      await gemContract.claim(nftId);

      // Show success toast
      toast({
        title: "Successfully claimed GEM",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Update the state with the amount of GEM to claim
      setGemToClaim('200000');

    } catch (error) {
      console.error('Error claiming GEM:', error);
      setLoading(false); // Set loading state to false

      // Check if the error message is specific to the NFT ID already being claimed
      if (error.message.includes("execution reverted: Already claimed")) {
        toast({
          title: "Already claimed",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else if (error.message.includes("execution reverted: Not NFT owner")) {
        toast({
          title: "Not NFT owner",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error claiming GEM",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } finally {
      setLoading(false); // Set loading state to false
    }
  };

  useEffect(() => {
    // Function to check if the NFT ID has already claimed GEM
    const checkNFTClaimed = async () => {
      try {
        if (!nftId) return;

        // Initialize provider
        const provider = new ethers.providers.Web3Provider(walletProvider);
        const signer = provider.getSigner();

        // Initialize contract instance
        const gemContract = new ethers.Contract(CLAIM_CONTRACT, CLAIM_ABI, signer);

        // Call the contract function to check if the NFT ID has already claimed GEM
        const { claimed } = await gemContract.claims(nftId);

        if (claimed) {
          setGemToClaim('Already claimed');
        } else {
          setGemToClaim('200000'); // If not claimed, set GEM to 200,000
        }
      } catch (error) {
        console.error('Error checking NFT claimed:', error);
        setGemToClaim('0');
      }
    };

    checkNFTClaimed();
  }, [nftId, walletProvider]);

  function btnClick() {
    window.location.href = "/";
  };

  const addTokenToWallet = async () => {
    try {
      if (!window.ethereum) {
        throw new Error('Metamask is not installed');
      }

      setAddingToWallet(true); // Set loading state to true while adding to wallet

      const response = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: '0x8d58A861cb7016554a362053B084887b509B8eDd',
            symbol: 'GEM',
            decimals: 18,
            image: 'https://ivory-deep-piranha-393.mypinata.cloud/ipfs/Qmce6svRn8svmdQMxCeqJcLWmJtrvpCZnNnxprzXT3qduy',
          },
        },
      });

      if (response) {
        toast({
          title: "Successfully added token to wallet",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Failed to add token to wallet",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error adding token to wallet:', error);
      toast({
        title: "Error adding token to wallet",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setAddingToWallet(false); // Reset loading state after adding to wallet
    }
  };

  return (
    <>
      <Box p={4} bg="#ffffff" color="white">
        <Flex alignItems="center">
          {/* Logo on the left */}
          <Box cursor='pointer' onClick={btnClick}>
            <img src="/logo.svg" alt="Logo" width="36px" />
          </Box>

          <Spacer />

          {/* Connect button on the right */}
          <Box display='flex' alignItems='center'>
            <Button onClick={onOpen} size='md' mr='2' bg='#C00038' color='white' _hover={{ bg: '#C00038', color: 'white' }} borderRadius='full'>Claim GEM</Button>
            <Box>
              {provider ? (
                <ConnectButton isConnected={true} />
              ) : (
                <ConnectButton isConnected={false} onClick={onOpen} />
              )}
            </Box>
          </Box>
        </Flex>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Claim GEM</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mt='2' mb='4' display='flex' justifyContent='center'>
              <Image h='80px' borderRadius='10' src='./logo.svg' />
            </Box>
            <Input size="md" mt='2' placeholder='Enter NFT ID' value={nftId} onChange={(e) => setNftId(e.target.value)} />
            <Input isDisabled size="md" mt='2' placeholder='Your GEM to claim' value={gemToClaim} />
          </ModalBody>
          <ModalFooter>
            <Box w='100%'>
              <Button bg='#C00038' color='white' _hover={{ bg: '#C00038', color: 'white' }} mb='2' size="lg" w='100%' onClick={claimGem} disabled={loading}>
                {loading ? <Spinner color="white" /> : "Claim"}
              </Button>
              <Button w='100%' colorScheme='orange' size='lg' onClick={addTokenToWallet} disabled={addingToWallet}>
                {addingToWallet ? <Spinner color="white" /> : "Add GEM to wallet"}
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Navigation;

import React from 'react';
import {
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Link,
    Text,
    useDisclosure
} from '@chakra-ui/react';

const Hero = () => {
    const { isOpen: isDisclaimerModalOpen, onOpen: onDisclaimerModalOpen, onClose: onDisclaimerModalClose } = useDisclosure();
    const { isOpen: isPrivacyModalOpen, onOpen: onPrivacyModalOpen, onClose: onPrivacyModalClose } = useDisclosure();
    const { isOpen: isTermsModalOpen, onOpen: onTermsModalOpen, onClose: onTermsModalClose } = useDisclosure();
    const { isOpen: isContractModalOpen, onOpen: onContractModalOpen, onClose: onContractModalClose } = useDisclosure();

    return (
        <>
            <Box p={4} display='flex' justifyContent='center'>
            <Link onClick={onContractModalOpen} mr='4'>
                    Contracts
                </Link>
                <Link onClick={onDisclaimerModalOpen} mr='4'>
                    Disclaimer
                </Link>
                <Link onClick={onPrivacyModalOpen} mr='4'>
                    Privacy Policy
                </Link>
                <Link onClick={onTermsModalOpen}>
                    Terms of Use
                </Link>
            </Box>

            <Modal isOpen={isContractModalOpen} onClose={onContractModalClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Deployed Contracts</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box>
                            <Text fontSize="lg" fontWeight="bold" mb={2}>
                                GEM Token Contract - 0x8d58A861cb7016554a362053B084887b509B8eDd
                            </Text>
                            <Text fontSize="lg" fontWeight="bold" mb={2}>
                                RedRuby NFT Contract - 0x46D4524fbcb3C7aeA67Ff85Ed4ca8996F1acEA6B
                            </Text>
                            <Text fontSize="lg" fontWeight="bold" mb={2}>
                                GEM Claim Contract - 0xca6b551FE1532Aa7198ECa87570986d2c96f2433
                            </Text>
                            
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Modal isOpen={isDisclaimerModalOpen} onClose={onDisclaimerModalClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Disclaimer</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box>
                            <Text fontSize="lg" fontWeight="bold" mb={4}>
                                Disclaimer
                            </Text>
                            <Text mb={4}>
                                <strong>1. No Investment Advice:</strong> The information provided on
                                this website is for informational purposes only and should not be
                                construed as financial, investment, or trading advice. We do not
                                provide any guarantees of profits or specific outcomes from
                                participation in our project.
                            </Text>
                            <Text mb={4}>
                                <strong>2. NFT Market Risks:</strong> The NFT market is highly
                                speculative and volatile. Prices of NFTs can fluctuate significantly,
                                and there is a risk of loss of capital. Users should conduct their own
                                research and exercise caution when participating in NFT purchases or
                                trades.
                            </Text>
                            <Text mb={4}>
                                <strong>3. Technology Risks:</strong> Participation in our project
                                involves the use of blockchain technology and smart contracts. While we
                                strive to maintain the security and integrity of our platform, there
                                are inherent risks associated with the use of decentralized
                                technologies, including but not limited to software bugs, hacking, and
                                network failures.
                            </Text>
                            <Text mb={4}>
                                <strong>4. Regulatory Compliance:</strong> Users are responsible for
                                ensuring compliance with all applicable laws and regulations in their
                                jurisdiction regarding the purchase, sale, and ownership of NFTs. We do
                                not provide legal advice, and users should seek independent legal
                                counsel if they have any questions about the legality of their
                                activities.
                            </Text>
                            <Text mb={4}>
                                <strong>5. Third-Party Links:</strong> Our website may contain links
                                to third-party websites or resources. We are not responsible for the
                                content, accuracy, or reliability of any third-party websites, and the
                                inclusion of such links does not imply endorsement or affiliation.
                            </Text>
                            <Text mb={4}>
                                <strong>6. No Warranty:</strong> We make no warranties or
                                representations, express or implied, regarding the accuracy,
                                completeness, or reliability of the information provided on this
                                website. All information is provided "as is" without any warranty of
                                any kind.
                            </Text>
                            <Text mb={4}>
                                <strong>7. Changes to Disclaimer:</strong> We reserve the right to
                                modify or update this disclaimer at any time without prior notice. It
                                is your responsibility to review this disclaimer periodically for
                                changes.
                            </Text>
                            <Text>
                                By accessing or using our website, you agree to indemnify and hold
                                harmless RedRuby and its affiliates, officers, directors, employees,
                                and agents from any and all claims, damages, losses, liabilities,
                                costs, and expenses arising out of or in connection with your use of
                                the website or participation in our project. If you do not agree with
                                any part of this disclaimer, you should not access or use our website.
                            </Text>
                            <Text mb={4} mt={4} fontStyle="italic">
                                Last updated: 04/01/2024
                            </Text>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Modal isOpen={isPrivacyModalOpen} onClose={onPrivacyModalClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Privacy Policy</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box>
                            <Text fontSize="lg" fontWeight="bold" mb={4}>
                                Privacy Policy
                            </Text>
                            <Text mb={4}>
                                <strong>1. Information We Collect:</strong> We may collect personal
                                information such as your name, email address, and contact details when
                                you interact with our website or participate in our project.
                            </Text>
                            <Text mb={4}>
                                <strong>2. How We Use Your Information:</strong> We use the information
                                we collect to provide, maintain, and improve our services, communicate
                                with you, and personalize your experience.
                            </Text>
                            <Text mb={4}>
                                <strong>3. Information Sharing:</strong> We may share your information
                                with third-party service providers who assist us in operating our
                                website and providing our services. We may also disclose information in
                                response to legal requests or to protect our rights or the rights of
                                others.
                            </Text>
                            <Text mb={4}>
                                <strong>4. Data Security:</strong> We take reasonable measures to
                                protect the confidentiality and security of your information. However,
                                no method of transmission over the internet or electronic storage is
                                completely secure.
                            </Text>
                            <Text mb={4}>
                                <strong>5. Your Choices:</strong> You have the right to access, update,
                                and delete your personal information. You may also opt out of receiving
                                promotional communications from us.
                            </Text>
                            <Text>
                                <strong>6. Changes to This Privacy Policy:</strong> We may update this
                                Privacy Policy from time to time. We will notify you of any changes by
                                posting the new Privacy Policy on this page.
                            </Text>
                            <Text mb={4} mt={4} fontStyle="italic">
                                Last updated: 04/01/2024
                            </Text>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Modal isOpen={isTermsModalOpen} onClose={onTermsModalClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Terms of Use</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box>
                            <Text fontSize="lg" fontWeight="bold" mb={4}>
                                Terms of Use
                            </Text>
                            <Text mb={4}>
                                <strong>1. Use of Our Website:</strong> You may use our website for
                                lawful purposes only. You may not use our website in any way that
                                violates any applicable laws or regulations.
                            </Text>
                            <Text mb={4}>
                                <strong>2. Intellectual Property:</strong> All content on this website,
                                including but not limited to text, graphics, logos, images, and
                                software, is the property of RedRuby and is protected by copyright and
                                other intellectual property laws.
                            </Text>
                            <Text mb={4}>
                                <strong>3. User Conduct:</strong> You agree not to engage in any
                                conduct that could disrupt or interfere with the operation of our
                                website or the use of our services by others.
                            </Text>
                            <Text mb={4}>
                                <strong>4. Limitation of Liability:</strong> We shall not be liable for
                                any direct, indirect, incidental, special, or consequential damages
                                arising out of or in any way connected with your use of our website or
                                participation in our project.
                            </Text>
                            <Text mb={4} mt={4} fontStyle="italic">
                                Last updated: 04/01/2024
                            </Text>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default Hero;
